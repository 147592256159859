import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type UpdateTasksSettingsMutationVariables = Types.Exact<{
  input: Types.UpdateTasksSettingsInput;
}>;

export type UpdateTasksSettingsMutation = {
  __typename?: 'Mutation';
  updateTasksSettings: {
    __typename?: 'AppSettings';
    id: string;
    tasks_leader_visible_all: boolean;
    show_adjuster_email_service_provider_block: boolean;
    tasks_track_first_contact: boolean;
  };
};

export const UpdateTasksSettingsDocument = gql`
  mutation UpdateTasksSettings($input: UpdateTasksSettingsInput!) {
    updateTasksSettings(input: $input) {
      id
      tasks_leader_visible_all
      show_adjuster_email_service_provider_block
      tasks_track_first_contact
    }
  }
`;

export function useUpdateTasksSettingsMutation() {
  return Urql.useMutation<UpdateTasksSettingsMutation, UpdateTasksSettingsMutationVariables>(
    UpdateTasksSettingsDocument,
  );
}
