import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type SetTaskFirstContactMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.SetTaskFirstContactInput;
}>;

export type SetTaskFirstContactMutation = {
  __typename?: 'Mutation';
  setTaskFirstContact: { __typename?: 'ResponseMessage'; success?: boolean | null };
};

export const SetTaskFirstContactDocument = gql`
  mutation SetTaskFirstContact($id: ID!, $input: SetTaskFirstContactInput!) {
    setTaskFirstContact(id: $id, input: $input) {
      success
    }
  }
`;

export function useSetTaskFirstContactMutation() {
  return Urql.useMutation<SetTaskFirstContactMutation, SetTaskFirstContactMutationVariables>(
    SetTaskFirstContactDocument,
  );
}
