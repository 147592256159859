import * as Types from '../../../../types/graphql.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type AssignTaskPrimaryFormMutationVariables = Types.Exact<{
  task_id: Types.Scalars['ID'];
  template_id: Types.Scalars['ID'];
}>;

export type AssignTaskPrimaryFormMutation = {
  __typename?: 'Mutation';
  assignTaskPrimaryForm: { __typename?: 'TaskForm'; id: string };
};

export const AssignTaskPrimaryFormDocument = gql`
  mutation AssignTaskPrimaryForm($task_id: ID!, $template_id: ID!) {
    assignTaskPrimaryForm(task_id: $task_id, template_id: $template_id) {
      id
    }
  }
`;

export function useAssignTaskPrimaryFormMutation() {
  return Urql.useMutation<AssignTaskPrimaryFormMutation, AssignTaskPrimaryFormMutationVariables>(
    AssignTaskPrimaryFormDocument,
  );
}
