import './EmailSelect.scss';

import { CommonFieldRenderProps, FieldError, FieldHint, Label } from '@module/shared/forms';
import { classNames } from '@progress/kendo-react-common';
import {
  MultiSelect,
  MultiSelectBlurEvent,
  MultiSelectChangeEvent,
  MultiSelectFilterChangeEvent,
  MultiSelectProps,
  TagData,
} from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import {
  cloneElement,
  createElement,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { TaskEmailContact } from '../../../../types/graphql.generated';
import { EmailSelectItem, trimWhitespace } from '../../helpers';

type EmailTag = Pick<TaskEmailContact, 'name' | 'email' | 'role'>;

interface EmailSelectTagProps {
  email: EmailSelectItem;
}

const charLT = '<';
const charGT = '>';

export const EmailSelectTag = (props: EmailSelectTagProps) => {
  const { email } = props;

  return email.name !== undefined ? (
    <span>
      {email.name}
      {charLT}
      {email.email}
      {charGT}
    </span>
  ) : (
    <span>{email.email}</span>
  );
};

interface EmailAddressProps {
  addresses: Array<EmailTag>;
}

type EmailSelectProps = EmailAddressProps &
  CommonFieldRenderProps &
  Pick<
    MultiSelectProps,
    | 'id'
    | 'value'
    | 'placeholder'
    | 'required'
    | 'disabled'
    | 'onBlur'
    | 'onChange'
    | 'onFilterChange'
    | 'onFocus'
  > & { labelPosition: 'top' | 'left' };

interface EmailSelectChangeEvent extends MultiSelectChangeEvent {
  value: Array<EmailTag | EmailSelectItem>;
}

export const EmailSelect = (props: EmailSelectProps) => {
  const { label, labelPosition = 'top', addresses, name, id, valid, value } = props;
  const multiselectRef = useRef<MultiSelect>(null);

  useEffect(() => {
    // this should remove the browser autocomplete
    if (id) {
      // we need to select the dom element that way, as the kendo element doesnt provide the option to set an
      // autocomplete attribute or allows to render a custom input element.
      const input = document.getElementById(id);
      if (input) {
        // maybe this is just a temporary solution, as chrome patches these things quite often
        // the current solution came up in June 2023 and is from the following source:
        // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
        input.setAttribute('autocomplete', 'new-password');
      }
    }
  }, [id]);

  const [filter, setFilter] = useState('');

  const handleChange = useCallback(
    (e: EmailSelectChangeEvent) => {
      const valuesWithName = e.value.map<EmailSelectItem>((value) => ({
        email: value.email,
        name: value.name ?? value.email,
      }));
      props.onChange?.({
        ...e,
        value: valuesWithName,
      });
    },
    [props],
  );
  const handleFilterChange = useCallback(
    (e: MultiSelectFilterChangeEvent) => {
      const isPasteEvent =
        e.nativeEvent instanceof InputEvent && e.nativeEvent.inputType === 'insertFromPaste';
      const value = isPasteEvent ? trimWhitespace(e.filter.value) : e.filter.value;

      setFilter(value);
      props.onFilterChange?.(e);
    },
    [props],
  );
  const handleBlur = useCallback(
    (e: MultiSelectBlurEvent) => {
      if (filter && multiselectRef.current) {
        handleChange({
          syntheticEvent: e.syntheticEvent,
          value: [...(value || []), { email: filter, name: filter }],
          nativeEvent: e.nativeEvent,
          target: multiselectRef.current,
        });
        setFilter('');
      }
    },
    [filter, handleChange, value],
  );
  const itemRender = (
    li: ReactElement<HTMLLIElement>,
    itemProps: {
      index: number;
      dataItem: EmailTag;
    },
  ) => {
    const index = itemProps.index;
    const itemChildren = (
      <div className="EmailSelectDropDown" key={index}>
        <>{li.props.children}</>
        <span className="k-list-item-text">{itemProps.dataItem.name}</span>
        <span className="k-list-item-text">{itemProps.dataItem.role}</span>
      </div>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  const tagRender = (tagData: TagData, li: ReactElement) => {
    const emailItem = tagData.data[0] as EmailSelectItem;
    return cloneElement(li, li.props, [
      createElement(EmailSelectTag, { key: emailItem.email, email: emailItem }),
      li.props.children,
    ]);
  };

  return (
    <FieldWrapper className="EmailSelect">
      <div
        className={classNames(
          'k-d-flex k-gap-x-2 k-gap-y-1',
          labelPosition === 'top'
            ? 'k-d-flex-col'
            : 'k-d-flex k-align-items-center k-justify-content-between',
        )}
      >
        <Label
          label={label}
          name={name}
          className={classNames({
            'k-align-items-center k-justify-content-end k-mr-2 k-flex-shrink-0':
              labelPosition === 'left',
          })}
          style={{ width: labelPosition === 'left' ? 100 : undefined }}
          editorId={id}
          editorValid={valid}
        >
          {label}
        </Label>
        <MultiSelect
          ref={multiselectRef}
          textField="email"
          name={name}
          data={addresses}
          size="large"
          onChange={handleChange}
          onBlur={handleBlur}
          allowCustom
          id={id}
          filterable={true}
          filter={filter}
          onFilterChange={handleFilterChange}
          value={value}
          tagRender={tagRender}
          header={
            <div className="EmailSelectDropDownHeader">
              <span className="k-list-item-text">
                <h3>E-Mail</h3>
              </span>
              <span className="k-list-item-text">
                <h3>Name</h3>
              </span>
              <span className="k-list-item-text">
                <h3>Rolle</h3>
              </span>
            </div>
          }
          itemRender={itemRender}
        />
      </div>

      <div className="HintAndError">
        <FieldHint {...props} />
        <FieldError {...props} />
      </div>
    </FieldWrapper>
  );
};
