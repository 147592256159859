import { TaskItemFieldsFragment, TasksQueryVariables, useTasksQuery } from '@module/tasks/graphql';
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { cloneElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { SQLOperator, TasksSortAndFilterColumns } from '../../../types/graphql.generated';
import { trimWhitespace } from '../helpers';
import { useIsAppModeRisk } from '../hooks';

const baseColumns = [TasksSortAndFilterColumns.ID, TasksSortAndFilterColumns.IDENTIFIER];

const riskColumns = [
  ...baseColumns,
  TasksSortAndFilterColumns.POLICY_HOLDER_CUSTOMER_NUMBER,
  TasksSortAndFilterColumns.POLICY_HOLDER_INSURANCE_POLICY_NUMBER,
];
interface TaskListItemProps extends ListItemProps {
  dataItem: TaskItemFieldsFragment;
}
const getItemText = (dataItem: TaskItemFieldsFragment, isAppModeRisk: boolean) => {
  let text = dataItem.id;

  if (dataItem.identifier) text += `/ ${dataItem.identifier}`;

  if (isAppModeRisk) {
    const additionalPolicyHolder = dataItem.client?.client_additional_policy_holder;
    const customerNumber = additionalPolicyHolder?.customer_number;
    const insurancePolicyNumber = additionalPolicyHolder?.insurance_policy_number;

    if (customerNumber) text += ` / ${customerNumber}`;
    if (insurancePolicyNumber) text += ` / ${insurancePolicyNumber}`;
  }

  return text;
};

export const GlobalSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const { t } = useTranslation();
  const isAppModeRisk = useIsAppModeRisk();

  const handleSearch = (e: AutoCompleteChangeEvent) => {
    const isPasteEvent =
      e.nativeEvent instanceof InputEvent && e.nativeEvent.inputType === 'insertFromPaste';
    const value = isPasteEvent ? trimWhitespace(e.value) : e.value;
    setSearchTerm(value);
  };

  const variables: TasksQueryVariables = useMemo(() => {
    const columns = isAppModeRisk ? riskColumns : baseColumns;
    return {
      filter: {
        AND: [
          {
            OR: columns.map((col) => {
              return {
                column: col,
                operator: SQLOperator.LIKE,
                value: '%' + debouncedSearchTerm + '%',
              };
            }),
          },
        ],
      },
      sort: { columns: [] },
      pagination: {
        skip: 0,
        take: 5,
      },
    };
  }, [debouncedSearchTerm, isAppModeRisk]);

  const [{ data }] = useTasksQuery({ variables });

  const itemRender = (li: React.ReactElement<HTMLLIElement>, itemProps: TaskListItemProps) => {
    const dataItem = itemProps.dataItem;

    const itemText = getItemText(dataItem, isAppModeRisk);

    const itemChildren = (
      <Link
        to={'/tasks/' + dataItem.id}
        className="k-text-primary text-decoration-none"
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span>{itemText}</span>
        <span className="l-i-chevron-right" />
      </Link>
    );
    return cloneElement(li, li.props, itemChildren);
  };

  return (
    <AutoComplete
      onChange={handleSearch}
      size="large"
      className="!k-w-1/3"
      textField="id"
      value={searchTerm}
      placeholder={t('common.labels.globalTaskSearch')}
      data={data?.tasks.data}
      itemRender={itemRender}
    />
  );
};
